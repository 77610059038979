import React from 'react';
import Section from '../components/Section';
import Metadata from '../components/Metadata';

function embodiment() {
  return (
    <>
      <Metadata title='Embodiment' />
      <Section heading='Embodiment in der therapeutischen Kommunikation'>
        <p>
          Embodiment steht als Begriff für die Tatsache, dass psychische
          Prozesse natürlich auch Hardware-Komponenten beinhalten: mentale und
          körperliche Vorgänge sind engstens miteinander verwoben. So vollzieht
          sich auch Kommunikation nicht rein verbal, sondern verkörpert sich
          facettenreich. Zur Embodimentperspektive in der Therapie zählt
          folglich etwa der bewusste Einbezug der körpersprachlichen Motorik,
          die Wahrnehmung physiologischer Aktivierungsgrade sowie die
          Beobachtung neuronaler Prozesse wie z.B.  „Hyperscanning“. Die
          Wechselwirkungen zwischen Körper und Psyche sind nachweislich in beide
          Richtungen beeinflussbar: körperbasierte Interventionen und ein
          therapeutischer Fokus auf die Funktionen des autonomen Nervensystems
          können daher mentale Prozesse wie z.B. Problemlösestrategien
          nachhaltig verändern.
        </p>
      </Section>
    </>
  );
}

export default embodiment;
